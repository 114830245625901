import { Link } from "gatsby";
import { ClipboardCopyIcon, AtSymbolIcon } from "@heroicons/react/outline";
import copy from "copy-to-clipboard";
import React from "react";

const Result = ({ location, results }) => {
  return (
    <div>
      <div className="md:flex">
        <div className="p-4 bg-gray-50 text-center rounded-lg md:mr-4 md:w-1/2">
          <h3 className="order-1 text-6xl font-bold tracking-tight text-red-600">
            ${parseInt(results.cost).toLocaleString()}
          </h3>
          <p className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
            Cost per year
          </p>
        </div>
        <div className="p-4 bg-gray-50 text-center rounded-lg mt-6 md:mt-0 md:ml-4 md:w-1/2">
          <h3 className="order-1 text-6xl font-bold tracking-tight text-red-600">
            {parseInt(results.hours).toLocaleString()}
          </h3>
          <p className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
            Engineer hours per year
          </p>
        </div>
      </div>
      <p className="mt-6 text-lg leading-8 text-gray-500">
        Traditional standups are costly and often time intensive. In comparison,
        using the same metrics you provided but for an asynchronous standup
        (assuming it takes 5min on the high end to fill out the report), it
        would cost <b>${parseInt(results.asyncCost).toLocaleString()}</b> per
        year with{" "}
        <b>{parseInt(results.asyncHours).toLocaleString()} engineering hours</b>{" "}
        used up. That is a saving of{" "}
        <b className="text-green-500">
          ${Math.round(results.cost - results.asyncCost).toLocaleString()}
        </b>{" "}
        and{" "}
        <b className="text-green-500">
          {Math.round(results.hours - results.asyncHours).toLocaleString()}{" "}
          hours
        </b>{" "}
        per year along with the{" "}
        <Link className="text-indigo-600" to="/blog/async-standups-benefits">
          other benefits
        </Link>{" "}
        of an asynchronous standup.
      </p>
      <div className="mt-6 text-center">
        <button
          type="button"
          className="inline-flex items-center rounded-md border-2 border-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-indigo-600 mx-2 shadow-sm hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => {
            copy(location.href);
          }}
        >
          <ClipboardCopyIcon
            className="-ml-0.5 mr-2 h-4 w-4"
            aria-hidden="true"
          />
          Copy shareable link
        </button>
        <a
          className="inline-flex items-center rounded-md border-2 border-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-indigo-600 mx-2 shadow-sm hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          href={`mailto:?subject=Standup%20cost&body=Check%20this%20out%3A%20${encodeURIComponent(
            location.href,
          )}`}
        >
          <AtSymbolIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          Share results by email
        </a>
      </div>
    </div>
  );
};

export default Result;
