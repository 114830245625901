import React from "react";

const Form = ({ formData, setFormData, onCalculate }) => {
  const handleChange = (e) => {
    const target = e.target;
    const val = target.value.replace(/\D/, "");
    const name = target.name;

    setFormData({
      ...formData,
      [name]: val,
    });
  };

  return (
    <div>
      <div className="rounded-lg bg-white shadow-lg">
        <div className="p-6">
          <h2 className="text-xl font-bold text-gray-700 mb-4">
            Tell us about your standup:
          </h2>

          <div className="flex justify-center items-center mb-4">
            <label
              htmlFor="length"
              className="block text-sm  text-gray-600 w-1/2 pr-3"
            >
              How long are your standups meetings?
            </label>
            <div className="relative mt-1 rounded-md shadow-sm w-1/2">
              <input
                type="text"
                name="length"
                id="length"
                className="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onChange={handleChange}
                value={formData.length}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  minutes
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center mb-4">
            <label
              htmlFor="frequency"
              className="block text-sm  text-gray-600 w-1/2 pr-3"
            >
              How many standup meetings per week?
            </label>
            <div className="relative mt-1 rounded-md shadow-sm w-1/2">
              <input
                type="text"
                name="frequency"
                id="frequency"
                className="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onChange={handleChange}
                value={formData.frequency}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  meetings
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center mb-4">
            <label
              htmlFor="participants"
              className="block text-sm  text-gray-600 w-1/2 pr-3"
            >
              How many people participate in it?
            </label>
            <div className="relative mt-1 rounded-md shadow-sm w-1/2">
              <input
                type="text"
                name="participants"
                id="participants"
                className="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onChange={handleChange}
                value={formData.participants}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  participants
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center mb-6">
            <label
              htmlFor="salary"
              className="block text-sm  text-gray-600 w-1/2 pr-3"
            >
              What is the average participant salary?
            </label>
            <div className="relative mt-1 rounded-md shadow-sm  w-1/2">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <input
                type="text"
                name="salary"
                id="salary"
                className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onChange={handleChange}
                value={formData.salary}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  /year
                </span>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm  text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={onCalculate}
          >
            Calculate Cost
          </button>
        </div>
      </div>
    </div>
  );
};

export default Form;
