import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";

import Form from "./form";
import Result from "./result";

const CostCalculator = ({ location }) => {
  const [formData, setFormData] = useState({
    length: 30,
    frequency: 5,
    participants: 8,
    salary: 100000,
  });
  const [results, setResults] = useState(false);

  useEffect(() => {
    if (location?.search) {
      const searchParams = new URLSearchParams(location.search);
      const inputs = searchParams.get("inputs");

      if (inputs) {
        const [length, frequency, participants, salary] = inputs.split(",");
        setFormData({
          length,
          frequency,
          participants,
          salary,
        });
      }
    }
  }, [location]);

  useEffect(() => {
    onCalculate();
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    if (results) {
      navigate(
        `/standup-cost-calculator?inputs=${formData.length},${formData.frequency},${formData.participants},${formData.salary}`,
        { replace: true },
      );
    }
  }, [JSON.stringify(results)]);

  const onCalculate = () => {
    const lengthInt = parseInt(formData.length);
    const frequencyInt = parseInt(formData.frequency);
    const participantsInt = parseInt(formData.participants);
    const salaryInt = parseInt(formData.salary);

    if (
      isNaN(lengthInt) ||
      isNaN(frequencyInt) ||
      isNaN(participantsInt) ||
      isNaN(salaryInt)
    ) {
      return;
    }

    const hoursPerWeek = (lengthInt / 60) * frequencyInt;
    const salaryToHourly = salaryInt / 52 / 40; // Based on 40 hours per week
    const costPerYear = hoursPerWeek * salaryToHourly * 52;
    const hoursPerYear = hoursPerWeek * 52;

    const asyncCost = (5 / 60) * frequencyInt * salaryToHourly * 52;
    const asyncHours = (5 / 60) * frequencyInt * 52;

    setResults({
      cost: Math.round(costPerYear * participantsInt),
      hours: Math.round(hoursPerYear * participantsInt),
      asyncCost: Math.round(asyncCost * participantsInt),
      asyncHours: Math.round(asyncHours * participantsInt),
    });
  };

  return (
    <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div className="lg:grid lg:gap-10 lg:grid-cols-3">
        <div>
          <Form
            formData={formData}
            setFormData={setFormData}
            onCalculate={onCalculate}
          />
        </div>
        <div className="mt-10 lg:mt-0 lg:col-span-2">
          <Result results={results} location={location} />
        </div>
      </div>
    </div>
  );
};

export default CostCalculator;
