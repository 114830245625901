import React from "react";

import CallToAction from "../components/call-to-action";
import Hero from "../components/calculator-hero";
import Footer from "../components/footer";
import Metadata from "../components/metadata";
import CostCalculator from "../components/cost-calculator";

const StandupCostCalculator = ({ location }) => {
  return (
    <>
      <Metadata title="Standup cost calculator - StandupWizard" />
      <Hero />
      <CostCalculator location={location} />
      <CallToAction />
      <Footer />
    </>
  );
};

export default StandupCostCalculator;
